<template lang="html">
<v-model-table ref="mergesRequestsTable" model-name="mergesRequests" :model-api="['models','Patient','MergesRequests']"
  api-order-by="id,desc"
  model-title="ขอรวม/เปลี่ยนเลขประจำตัวผู้รับวัคซีน"
  :headers="headers" serverPagination>
  <template v-slot:modelForm="{data,rules,isCreating}">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field :rules="[rules.require()]" v-model="data.fromHn" label="จากเลขประจำตัว"></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field :rules="[rules.require()]" v-model="data.toHn" label="เป็นเลขประจำตัว"></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </template>
  <template v-slot:item.created_at="props">
    <v-label-datetime :date-time="props.item.created_at" short-date-time></v-label-datetime>
  </template>
  <template v-slot:item.isSuccess="props">
    <v-icon :color="(props.item.isSuccess) ? 'green darken-2' : 'red'">{{ (props.item.isSuccess) ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline' }}</v-icon>
  </template>
  <template v-slot:item.created_by="props">
    <v-label-user :username="props.item.created_by"></v-label-user>
  </template>
</v-model-table>
</template>

<script>
export default {
  data: ()=>({
    slotDate: undefined,
    headers: [
      {
        text: 'วันที่ทำการขอรวม',
        value: 'created_at',
        class: 'body-1',
      },
      {
        text: 'จากเลข',
        value: 'fromHn',
        class: 'body-1',
      },
      {
        text: 'เป็นเลข',
        value: 'toHn',
        class: 'body-1',
      },
      {
        text: 'สถานะ',
        value: 'isSuccess',
        class: 'body-1',
      },
      {
        text: 'หมายเหตุ',
        value: 'note',
        class: 'body-1',
      },
      {
        text: 'ผู้ขอ',
        value: 'created_by',
        class: 'body-1',
      },
    ],
  }),
}
</script>

<style lang="css" scoped>
</style>
